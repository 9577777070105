<template>
  <section>
    <section id="monster" class="container">
      <h2>Monster Health</h2>
      <div class="healthbar">
        <div class="healthbar__value" :style="monsterHealthBarWidth"></div>
      </div>
    </section>
    <section id="player" class="container">
      <h2>Your Health</h2>
      <div class="healthbar">
        <div class="healthbar__value" :style="playerHealthBarWidth"></div>
      </div>
    </section>
    <section class="container" v-if="isGameOver">
      <h2>Game Over!</h2>
      <h3>{{ gameOverMessage }}</h3>
      <button @click="newGame">NEW GAME</button>
    </section>
    <section id="controls" v-else>
      <button @click="attackMonster">ATTACK</button>
      <button @click="specialAttackMonster" :disabled="specialAttackDisabled">
        SPECIAL ATTACK
      </button>
      <button @click="healPlayer" :disabled="healingDisabled">HEAL</button>
      <button @click="surrender">SURRENDER</button>
    </section>
    <section id="log" class="container">
      <h2>Battle Log</h2>
      <ul v-if="logMessages.length > 0">
        <li v-for="message in logMessages" :key="message.id">
          <span :class="styleLogEntity(message.by)">{{ message.by }}</span>
          <span>{{ formatLogAction(message.action) }}</span>
          <span v-if="message.value" :class="styleLogValue(message.action)">{{
            message.value
          }}</span>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
const playerMaxHealth = 100;
const playerMinAttack = 5;
const playerMaxAttack = 12;
const playerMinSpecialAttack = 10;
const playerMaxSpecialAttack = 25;
const playerMinHeal = 15;
const playerMaxHeal = 25;

const specialAttackCooldown = 5;
const healingCooldown = 5;

const monsterMaxHealth = 100;
const monsterMinAttack = 8;
const monsterMaxAttack = 15;

const ENTITY = {
  PLAYER: "Player",
  MONSTER: "Monster",
};

const ACTION = {
  ATTACK: "attack",
  HEAL: "heal",
  SURRENDER: "surrender",
};

const DRAW = "draw";

let logMessageId = 0;

function getRandomValue(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

function getHealthBarColor(hp) {
  if (hp >= 50) return "#00a876";
  else if (hp < 50 && hp >= 25) return "#a88400";
  else return "#a84300";
}

export default {
  data() {
    return {
      monsterHealth: monsterMaxHealth,
      playerHealth: playerMaxHealth,
      specialAttackCooldown: 0,
      healingCooldown: 0,
      isGameOver: false,
      winner: "",
      logMessages: [],
    };
  },
  computed: {
    monsterHealthBarWidth() {
      return {
        width: this.monsterHealth + "%",
        backgroundColor: getHealthBarColor(this.monsterHealth),
      };
    },
    playerHealthBarWidth() {
      return {
        width: this.playerHealth + "%",
        backgroundColor: getHealthBarColor(this.playerHealth),
      };
    },
    specialAttackDisabled() {
      return this.specialAttackCooldown ? true : false;
    },
    healingDisabled() {
      return this.healingCooldown ? true : false;
    },
    gameOverMessage() {
      if (this.winner === DRAW) {
        return "Draw!";
      } else if (this.winner === ENTITY.MONSTER) {
        return "You lose!";
      } else {
        return "You win!";
      }
    },
  },
  watch: {
    playerHealth(value) {
      if (value === 0 && this.monsterHealth === 0) {
        this.isGameOver = true;
        this.winner = DRAW;
      } else if (value === 0) {
        this.isGameOver = true;
        this.winner = ENTITY.MONSTER;
      }
    },
    monsterHealth(value) {
      if (value === 0 && this.playerHealth === 0) {
        this.isGameOver = true;
        this.winner = DRAW;
      } else if (value === 0) {
        this.isGameOver = true;
        this.winner = ENTITY.PLAYER;
      }
    },
  },
  methods: {
    attackMonster() {
      let attackStrength = getRandomValue(playerMinAttack, playerMaxAttack);
      if (attackStrength > this.monsterHealth)
        attackStrength = this.monsterHealth;
      this.monsterHealth -= attackStrength;

      this.logMessage(ENTITY.PLAYER, ACTION.ATTACK, attackStrength);
      this.attackPlayer();
    },
    attackPlayer() {
      let attackStrength = getRandomValue(monsterMinAttack, monsterMaxAttack);
      if (attackStrength > this.playerHealth)
        attackStrength = this.playerHealth;
      this.playerHealth -= attackStrength;

      this.logMessage(ENTITY.MONSTER, ACTION.ATTACK, attackStrength);
      if (this.specialAttackCooldown > 0) this.specialAttackCooldown--;
      if (this.healingCooldown > 0) this.healingCooldown--;
    },
    specialAttackMonster() {
      let attackStrength = getRandomValue(
        playerMinSpecialAttack,
        playerMaxSpecialAttack
      );
      if (attackStrength > this.monsterHealth)
        attackStrength = this.monsterHealth;
      this.monsterHealth -= attackStrength;

      this.logMessage(ENTITY.PLAYER, ACTION.ATTACK, attackStrength);
      this.specialAttackCooldown = specialAttackCooldown;
      this.attackPlayer();
    },
    healPlayer() {
      let healingStrength = getRandomValue(playerMinHeal, playerMaxHeal);
      if (healingStrength + this.playerHealth > 100)
        healingStrength = playerMaxHealth - this.playerHealth;
      this.playerHealth += healingStrength;
      this.healingCooldown = healingCooldown;
      this.logMessage(ENTITY.PLAYER, ACTION.HEAL, healingStrength);
      this.attackPlayer();
    },
    newGame() {
      this.playerHealth = playerMaxHealth;
      this.monsterHealth = monsterMaxHealth;
      this.specialAttackCooldown = 0;
      this.healingCooldown = 0;
      this.logMessages = [];
      this.winner = "";
      this.isGameOver = false;
    },
    surrender() {
      this.isGameOver = true;
      this.logMessage(ENTITY.PLAYER, ACTION.SURRENDER);
      this.winner = ENTITY.MONSTER;
    },
    logMessage(who, what, value) {
      this.logMessages.unshift({
        id: logMessageId++,
        by: who,
        action: what,
        value,
      });
    },
    styleLogValue(action) {
      if (action === ACTION.ATTACK) return "log--damage";
      else if (action === ACTION.HEAL) return "log--heal";
    },
    formatLogAction(action) {
      if (action === ACTION.ATTACK) return " attack deals ";
      else if (action === ACTION.HEAL) return " heals ";
      else if (action === ACTION.SURRENDER) return " surrenders ";
    },
    styleLogEntity(entity) {
      if (entity === ENTITY.PLAYER) return "log--player";
      else if (entity === ENTITY.MONSTER) return "log--monster";
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: "Jost", sans-serif;
}

body {
  margin: 0;
}

header {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.5rem;
  background-color: #880017;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
}

section {
  width: 90%;
  max-width: 40rem;
  margin: auto;
}

.healthbar {
  width: 100%;
  height: 40px;
  border: 1px solid #575757;
  margin: 1rem 0;
  background: #fde5e5;
}

.healthbar__value {
  background-color: #00a876;
  width: 100%;
  height: 100%;
}

.container {
  text-align: center;
  padding: 0.5rem;
  margin: 1rem auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
}

#monster h2,
#player h2 {
  margin: 0.25rem;
}

#controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

button {
  font: inherit;
  border: 1px solid #88005b;
  background-color: #88005b;
  color: white;
  padding: 1rem 2rem;
  border-radius: 12px;
  margin: 1rem;
  width: 12rem;
  cursor: pointer;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.26);
}

button:focus {
  outline: none;
}

button:hover,
button:active {
  background-color: #af0a78;
  border-color: #af0a78;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.26);
}

button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  box-shadow: none;
  color: #3f3f3f;
  cursor: not-allowed;
}

#log ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#log li {
  margin: 0.5rem 0;
}

.log--player {
  color: #7700ff;
}

.log--monster {
  color: #da8d00;
}

.log--damage {
  color: red;
}

.log--heal {
  color: green;
}
</style>
